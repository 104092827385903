import React, { useState, useCallback } from "react";
import axios from "axios";
import "./App.css";
import imgLogo from "./Images/otravoLogo.png";

const accessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmRlcl9jb2RlIjpudWxsLCJzY29wZSI6WyJvcnBoZXVzLXJlcG9ydGluZyIsImNhbXVzLXJlcG9ydGluZyJdLCJleHAiOjE2NzE2NTk1NzMsImp0aSI6ImM3ZDJlMDRiLTViZWEtNGU3Yy1hODQ2LTY3M2MzY2RiMGVjNyIsImNsaWVudF9pZCI6Im90cmF2b19ub3J0aCJ9.jEARyXA_11Gk6Q7WBbmV-8wuRuTcsmwSF_6HDgbPzJU";
const apiUrl = "https://op-api.travelagency.tech/reservations";

const authAxios = axios.create({
  baseURL: apiUrl,
  headers: { Authorization: `Bearer ${accessToken}` },
});

function App() {
  const [requestError, setRequestError] = useState("");
  const [inputForm, setInputForm] = useState("");
  const [url, setUrl] = useState("");
  const [copySuccess, setCopySuccess] = useState();

  const copy = async () => {
    await navigator.clipboard.writeText(url);
    setCopySuccess("Text copied");
  };

  const getNewData = async (e) => {
    e.preventDefault();
    setRequestError();
    setUrl();
    const result = await authAxios.get(`${apiUrl}?text=${inputForm}`);
    const response = await result;
    setCopySuccess();
    if (!response.data.empty) {
      if (
        response.data.content[0].brand === "TRAVELGENIO" ||
        response.data.content[0].brand === "TRAVEL2BE" ||
        response.data.content[0].brand === "TRIPMONSTER" ||
        response.data.content[0].brand === "SCHIPOLTICKETS"
      ) {
        return setUrl(
          `https://${response.data.content[0].country
            .toString()
            .toLowerCase()}.${response.data.content[0].brand
            .toString()
            .toLowerCase()}.com/booking/receipt/${response.data.content[0].reservationCode
            .toString()
            .toLowerCase()}/
        `
        );
      } else {
        return setUrl(
          `https://www.${response.data.content[0].brand
            .toString()
            .toLowerCase()}.${response.data.content[0].country
            .toString()
            .toLowerCase()}/booking/receipt/${response.data.content[0].reservationCode
            .toString()
            .toLowerCase()}/`
        );
      }
    } else {
      setRequestError("Wrong Code.");
    }
  };

  return (
    <div className="App">
      <div className="otravoLogo">
        <img src={imgLogo} alt="" />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <form onSubmit={(e) => getNewData(e)}>
          <input
            type="text"
            maxLength="8"
            pattern={`[0-9]{8}`}
            placeholder="8-digit code"
            value={inputForm}
            onChange={(e) => setInputForm(e.target.value)}
          />
          <input
            type="submit"
            style={{
              backgroundColor: "#ffe800",
              color: "#000000",
              margin: "2px",
              borderRadius: "24px",
            }}
            value="Search for Reciept"
          />
        </form>
      </div>
      <div>
        <h1>{url}</h1>
      </div>
      {url?.length > 0 && (
        <button onClick={copy} disabled={!url}>
          {copySuccess ? "Copied to clipboard" : "Copy to Clipboard"}
        </button>
      )}
      {requestError && <p className="error">{requestError}</p>}
    </div>
  );
}
export default App;
